import * as React from 'react'
import Footer from 'components/Footer/footer'
import { StaticQuery, graphql } from 'gatsby'
import { Languages } from 'i18n/config'

interface FooterRenderProps {
  className?: string
}
const FooterRender: React.FC<FooterRenderProps> = ({ className }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          footerYaml(lang: { eq: "zh-TW" }) {
            lang
            nav_list {
              group_name
              nav_name
              list {
                name
                link
                is_open_new_tab
                is_outside_link
              }
            }
          }
        }
      `}
      render={({ footerYaml }) => (
        <Footer
          className={className}
          lang={Languages.TW}
          messageList={[
            {
              label: 'Tel',
              value: '0086-4006509827',
              url: 'tel:0086-4006509827',
            },
            {
              label: 'E-mail',
              value: 'contact@sensorsdata.cn',
              url: 'mailto:contactus@sensorsdata.com',
            },
            // {
            //   label: '地址',
            //   value: '中國台灣省台北市中山區南京東路3段168號14樓',
            // },
          ]}
          // copy="Copyright © 中國台北神策諮詢顧問有限公司"
          // record="ALL RIGHTS RESERVED"
          qrcodeList={[
            {
              title: '關注 WeChat 公眾號',
              url: 'https://www.sensorsdata.com/imgs/FooterCmp/footer_logo1.png',
            },
            // {
            //   title: '加入 Line 好友',
            //   url: 'https://www.sensorsdata.com/imgs/FooterCmp/footer_logo2.png',
            // },
          ]}
          menuList={footerYaml?.nav_list}
        />
      )}
    />
  )
}

export default FooterRender
