import * as React from 'react'
import { BackTop } from '@eefe/gw-components'
import Provider, { User } from 'components/Provider'
import Layout from '../layout'
import HeaderRender from './HeaderRender'
import FooterRender from './FooterRender'
import useHeaderFixed from 'hooks/useHeaderFixed'
import message from 'i18n/tw'
import { Languages } from 'i18n/config'

interface LayoutParams {
  className?: string
  footerClassName?: string
  userUpdate?: boolean
  onUserInfo?: (data: User | null) => void
  hideFooter?: boolean
  header?: any
  showLangDropdown?: boolean
  headerDemoUrl?: string
  navList?: any
  [propName: string]: any
}

const LayoutTW: React.FC<LayoutParams> = ({
  location,
  children,
  footerClassName,
  userUpdate,
  onUserInfo,
  hideFooter,
  header,
  headerDemoUrl = '/tw/demo/demo',
  showLangDropdown = false,
}) => {
  const fixed = useHeaderFixed()

  return (
    <Provider isUpdate={userUpdate} onUserInfo={onUserInfo} lang={Languages.TW}>
      <Layout location={location} i18nMessages={message}>
        {/* Header */}
        {header ? header : <HeaderRender fixed={fixed} showLangDropdown={showLangDropdown} />}
        {/* Body */}
        {children}
        {/* Footer */}
        {!hideFooter && <FooterRender className={footerClassName} />}

        {/* 返回顶部 */}
        <BackTop />
      </Layout>
    </Provider>
  )
}

export default LayoutTW
