/* eslint-disable */
import React, {useEffect, useState}  from 'react'
import classnames from 'classnames'
import { Languages } from 'i18n/config'
import * as styles from './footer.module.less'
import { isPc } from 'utils/utils';

interface FootMenuProps {
  title?: string
  subTitle?: string
  list?: {
    name?: string
    url?: string
    className?: string
    target?: string
    rel?: string
  }[]
}
interface FooterProps {
  className?: string
  lang?: Languages
  messageList?: {
    label?: string
    value?: string
    url?: string
  }[]
  copy?: string | React.ReactNode
  record?: string | React.ReactNode
  qrcodeList?: {
    title?: string
    url?: string
  }[]
  quickLink?:string
  menuList?: any
  linkTitle?: string
  license?: { title: string; url: string }
}
const Footer: React.FC<FooterProps> = (props) => {
  const { className, lang, copy, record, messageList, qrcodeList, quickLink, menuList, license, linkTitle } = props
  const [show, setShow] = useState(isPc() ? true :false)

  const cnames = classnames(styles.footerContainer, className, {
    [styles.I18n]: !!lang,
  })

  const handleMenuList = () => {
    const len = menuList.length
    let newMenu:any = [];
    for (let i = 0; i < len;) {
      let menuItemArr = menuList.slice(i, i += 4)
      newMenu.push(menuItemArr)
    }
    return newMenu;
  }

  const handleTaggle = () => {
    setShow(!show)
  }

  return (
    <footer className={cnames}>
      <div className={classnames(styles.mainContent)}>
        <div className={styles.footerLeft}>
          <div className={classnames('flex lg:hidden justify-between py-[1rem] text-[#666] text-[1.4rem] px-[2rem]', {'border-b border-[#D2D7DF]': !show})} onClick={handleTaggle}>
            <span>{quickLink}</span>
            <span>
              {
                show ? <img src={require('assets/images/home/arrow_down.png').default} />:<img src={require('assets/images/home/arrow_up.png').default} />
              }
            </span>
          </div>
          {
            show && (
              <ul className={styles.navList}>
            {handleMenuList()?.map((menu, index: number) => (
              <li key={index} className={styles.navItem}>
                {
                  menu.map(item => (
                    <p className={styles.menuItem} key={item.nav_name}>
                      <a href={item?.link}>{item?.nav_name}</a>
                    </p>
                  ))
                }
              </li>
              ))}
            </ul>
            )
          }
          
        </div>
        <div className={styles.footerRight}>
          <div className='mb-[10px]'>{linkTitle}</div>
          <ul className={styles.contactInfo}>
            {messageList?.map((item, index) => (
              <li key={index} className={styles.infoItem}>
                <a href={item.url}>{item.label}：{item.value}</a>
              </li>
            ))}
          </ul>
          <div className={styles.qrcodeList}>
            {qrcodeList?.map((item, index) => (
              <div className={styles.qrcodeItem} key={index}>
                <img className={styles.qrcode} src={item.url} alt="qrcode" />
                <p className='text-center text-[12px] mt-[10px]'>{item.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="block lg:hidden text-[12px] text-center leading-[43px] color-[#666]">
        {copy}
      </div>
      <div className="hidden lg:block text-center">
        <span className="text-[14px] px-[6px] leading-[56px] color-[#666]">{copy}</span>
        <span className="text-[14px] px-[6px] leading-[56px] color-[#666]">{record}</span>
        <span className="text-[14px] px-[6px] leading-[56px] color-[#666]">
          <a href={license?.url} target="_blank">
            {license?.title}
          </a>
        </span>
      </div>
    </footer>
  )
}

export default Footer

/* eslint-disable no-new */
