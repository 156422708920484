import React from "react";
import Layout from "layouts/tw";
import Seo from "components/seo"
import SectionContent from 'components/SectionContent'
import PageBottomCard from 'components/PageBottomCard'
import DemoCard, { DemoCardGroup, DemoCardMobile } from 'components/Demo/DemoCard';
import { Languages } from 'i18n/config'

import * as styles from './index.module.less'
import i18n from 'i18n/tw/i18n_demo.json';


const DemoList: React.FC = (props) => {
  const paradeUrl = `/${Languages.TW}/parade/sa`;

  return (
    <Layout 
      {...props}
      headerDemoUrl={''}
    >
      <DemoListState 
        paradeUrl={paradeUrl}
      />
    </Layout>
  );
};

interface DemoListStateProps {
  className?: string;
  paradeUrl?: string;
}
export const DemoListState: React.FC<DemoListStateProps> = props => {
  const {
    paradeUrl
  } = props;
  const demoList = [
    {
      icon: require('assets/images/demo/d1.svg').default,
      title: i18n['i18n_demo_main_3'],
      desc: i18n['i18n_demo_main_4'],
      buttonList: [{
        text: i18n['i18n_demo_main_5'],
        mobileTitle: i18n['i18n_demo_main_3'],
        href: '/doEbizTW'
      }]
    },
    {
      icon: require('assets/images/demo/d8.svg').default,
      title: i18n['i18n_demo_main_6'],
      desc: i18n['i18n_demo_main_7'],
      buttonList: [{
        text: i18n['i18n_demo_main_8'],
        mobileTitle: i18n['i18n_demo_main_6'],
        href: '/doSecurityTW'
      }]
    },
    {
      icon: require('assets/images/demo/d6.svg').default,
      title: i18n['i18n_demo_main_27'],
      desc: i18n['i18n_demo_main_28'],
      buttonList: [{
        text: i18n['i18n_demo_main_29'],
        mobileTitle: i18n['i18n_demo_main_27'],
        href: '/doEducationTW'
      }]
    },
    {
      icon: require('assets/images/demo/d10.svg').default,
      title: i18n['i18n_demo_main_77'],
      desc: i18n['i18n_demo_main_78'],
      buttonList: [{
        text: i18n['i18n_demo_main_29'],
        mobileTitle: i18n['i18n_demo_main_77'],
        href: '/doBankTW'
      }]
    },
    {
      icon: require('assets/images/demo/d7.svg').default,
      title: i18n['i18n_demo_main_79'],
      desc: i18n['i18n_demo_main_80'],
      buttonList: [{
        text: i18n['i18n_demo_main_29'],
        mobileTitle: i18n['i18n_demo_main_79'],
        href: '/doGameTW'
      }]
    },
    {
      icon: require('assets/images/demo/d4.svg').default,
      title: i18n['i18n_demo_main_81'],
      desc: i18n['i18n_demo_main_82'],
      buttonList: [{
        text: i18n['i18n_demo_main_29'],
        mobileTitle: i18n['i18n_demo_main_81'],
        href: '/doNewRetailTW'
      }]
    },
    {
      icon: require('assets/images/demo/d20.svg').default,
      title: i18n['i18n_demo_main_83'],
      desc: i18n['i18n_demo_main_84'],
      buttonList: [{
        text: i18n['i18n_demo_main_29'],
        mobileTitle: i18n['i18n_demo_main_83'],
        href: '/doContentTW'
      }]
    },
    {
      icon: require('assets/images/demo/d15.svg').default,
      title: i18n['i18n_demo_main_85'],
      desc: i18n['i18n_demo_main_86'],
      buttonList: [{
        text: i18n['i18n_demo_main_29'],
        mobileTitle: i18n['i18n_demo_main_85'],
        href: '/doInsuranceTW'
      }]
    },
  ];

  return (
    <>
      <Seo 
        title={i18n['i18n_demo_head_pageTitle']}
        description={i18n['i18n_demo_head_description']}
        keywords={i18n['i18n_demo_head_keywords']}
        saTitle="" 
      />
      <main>
        <SectionContent className={styles.DemoListContainer} title={i18n['i18n_demo_main_2']} backgroundColor="#F8FBFD">
          <DemoCardGroup className={styles.DemoCardGroup}>
            {demoList.map(item => <DemoCard 
              icon={item.icon}
              title={item.title}
              desc={item.desc}
              buttonList={item.buttonList}
            />)}
          </DemoCardGroup>
          <DemoCardGroup className={styles.DemoCardGroupMb}>
            {demoList.map(demo => demo.buttonList.map(item => <DemoCardMobile 
              icon={demo.icon}
              title={item.mobileTitle}
              href={item.href}
            />))}
          </DemoCardGroup>
        </SectionContent>
        <PageBottomCard
          title={i18n['i18n_demo_main_73']}
          desc={i18n['i18n_demo_main_74']}
          rightButtonText={i18n['i18n_demo_main_76']}
          rightButtonHref={paradeUrl}
        />
      </main>
    </>
  );
}

export default DemoList;
