// extracted by mini-css-extract-plugin
export var contactInfo = "footer-module--contactInfo--vKzoS";
export var footerContainer = "footer-module--footerContainer--qwjDM";
export var footerLeft = "footer-module--footerLeft--oB+SE";
export var footerRight = "footer-module--footerRight--+o9hN";
export var infoItem = "footer-module--infoItem--eIELB";
export var mainContent = "footer-module--mainContent--5dOa+";
export var menuItem = "footer-module--menuItem--C5FQS";
export var navItem = "footer-module--navItem--Xy5uJ";
export var navList = "footer-module--navList--hDdUY";
export var qrcode = "footer-module--qrcode--1zE6F";
export var qrcodeItem = "footer-module--qrcodeItem--MoGDt";
export var qrcodeList = "footer-module--qrcodeList--Kc8jI";