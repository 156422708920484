import React, { useState, useEffect } from "react"

const useHeaderFixed = () => {
  const [fixed, setFixed] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener('scroll', updateFixed);
    updateFixed();
    return () => {
      document.removeEventListener('scroll', updateFixed);
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const updateFixed = () => {
    var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    var value = scrollTop > 20;
    if (value) {
      document.body.classList.add('header-fixed')
    } else {
      document.body.classList.remove("header-fixed");
    }
    setFixed(value);
  }
  return fixed;
}

export default useHeaderFixed;